@import "~@department-of-veterans-affairs/css-library/dist/stylesheets/mixins";

.email-signup-form {
  &.form-panel {
    margin: 0 auto;

    @media screen and (max-width: 767px) {
      max-width: 30rem;
    }
  }

  #email-signup-form {
    .homepage-email-input::part(form-header) {
      font-size: 1.25rem;
    }
  }
}