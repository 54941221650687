.header-us-flag {
  height: 20px;
}

.expand-official-govt-explanation {
  font-size: 12px; // Smallest is vads-u-font-size--sm which is 15px.
}

.govt-expanded-arrow {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}