@import "~@department-of-veterans-affairs/css-library/dist/tokens/scss/variables";

// Override VSDS Alert component icon to fix overlapping custom icon
.usa-alert::before {
  background-image: none;
}

// Match the va-alert styling.
.mhv-c-reg-alert {
  border-left: 0.5rem solid;
  padding: 1rem 1.25rem 0.875rem 1.875rem;
  position: relative;

  &.mhv-u-reg-alert-warning {
    background-color: var(--vads-color-warning-lighter);
    border-left-color: var(--vads-color-warning);
  }

  &.mhv-u-reg-alert-success {
    background-color: var(--vads-color-success-lighter);
    border-left-color: var(--vads-color-success);
  }

  &.mhv-u-reg-alert-info {
    background-color: var(--vads-color-info-lighter);
    border-left-color: var(--vads-color-info);
  }

  va-icon {
    position: absolute;
    left: 12px;
    top: 12px;
  }

  h2  {
    font-size: 21.28px;
  }

  .mhv-u-reg-alert-body {
    font-size: 1.06rem;

    ul {
      margin: 0 0 0.5em 1em;
    }
  }

  @media (min-width: 64em) {
    .mhv-u-reg-alert-col {
      padding-left: 1rem;
      padding-right: 4rem;
    }

    va-icon {
      padding-left: 0.75rem;
    }
  }
}
